import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { defer } from "rxjs";
import { ERROR_MESSAGES } from "src/app/common/constants";
import { getS3Url } from "src/app/common/utils";
import { TypeAheadField } from "src/app/components/forms/models/field-typeahead";
import { getById } from "src/app/services/contract.service";
import { addContract, getUsers } from "src/app/services/manager.service";
import { updateFile, uploadFile } from "src/app/services/product.service";

@Component({
  selector: "app-add-contract",
  templateUrl: "./add-contract.component.html",
  styleUrls: ["./add-contract.component.scss"],
})
export class AddContractComponent implements OnInit {
  public detailsForm: FormGroup;
  public formFields: any[] = [];
  public contract: any;
  public loading = false;
  public fileName = "";
  constructor(
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.detailsForm = this.formBuilder.group({
      uploadContractUrl: ["", Validators.required],
      user: ["", Validators.required],
    });
    this.formFields = [
      new TypeAheadField({
        key: "user",
        label: "User",
        placeholder: "Search User..",
      }),
    ];
    if (this.activatedRoute.snapshot.paramMap.get("id")) {
      this.loadById(this.activatedRoute.snapshot.paramMap.get("id"));
    }
  }

  loadById = async (id) => {
    this.spinner.show();
    try {
      let { success, result } = await getById(id);
      if (success) {
        this.contract = result;
        const nonEditable = ["user"];
        for (let field of nonEditable) {
          this.detailsForm.controls[field].disable();
        }

        const fieldsToPatch = ["contract_title", "contract_description"];
        let patch = {};
        for (let field of fieldsToPatch) {
          if (field in this.contract) patch[field] = this.contract[field];
        }
        if (this.contract.user) {
          patch["user"] = {
            name: this.contract.user.id,
            value: this.getNameofUser(this.contract.user),
          };
        }
        this.detailsForm.patchValue(patch);
      }
    } catch (err) {
      this.toastr.error(
        err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };

  getNameofUser = (r) => {
    let names = [];
    if (r.first_name) {
      names.push(r.first_name);
    }
    if (r.last_name) {
      names.push(r.last_name);
    }
    return names.join(" ");
  };

  searchUser = (searchText: string) => {
    return defer(() => {
      return getUsers([1, 5], searchText, null, null, null, null).then(
        (results) => {
          if (results && results.results) {
            return results.results.map((r) => {
              return { name: r.id, value: this.getNameofUser(r) };
            });
          }
          return [];
        }
      );
    });
  };
  ngOnInit(): void {
    this.detailsForm.valueChanges.subscribe((data) => {
      console.log(data);
    });
  }

  async onFileChange(event: any) {
    const file = event.target.files[0];
    let params = {
      file_name: file.name,
      content_type: file.type,
    };
    this.loading = true;
    try {
      let { key, url } = (await updateFile(params)) || {};
      this.getBase64(file);
      await uploadFile(url, file);
      this.loading = false;
      const uploadContractUrl = getS3Url(key);
      this.detailsForm.get("uploadContractUrl")?.patchValue(uploadContractUrl);
      this.fileName = file.name;
    } catch (err) {
      this.loading = false;
      let message =
        err && err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR;
      this.toastr.error(message);
    }
  }
  getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  async saveContract() {
    const { uploadContractUrl, user } = this.detailsForm.value;
    if (uploadContractUrl && user?.name) {
      try {
        this.spinner.show();
        await addContract({
          manufacturer_id: user?.name,
          contract_url: uploadContractUrl,
        });
        this.router.navigate(["/manager/contracts"]);
        this.spinner.hide();
      } catch (error) {
        this.spinner.hide();
        console.log("Error: ", error);
      }
    }
  }
  removeFile(){
    this.fileName = ''
    this.detailsForm.get("uploadContractUrl")?.patchValue('');
  }
}
