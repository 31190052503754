import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import {
  ERROR_MESSAGES,
  PAGE_COUNT,
  SUCCESS_MESSAGES,
} from "src/app/common/constants";
import { ConfirmationModalComponent } from "src/app/components/modals/confirmation-modal/confirmation-modal.component";
import {
  SortEvent,
  TableSortableHeader,
} from "src/app/directives/tablesortableheader.directives";
import { Contract, ContractDocumentModel } from "src/app/models/contract";
import { deleteById, getAll } from "src/app/services/contract.service";
import { fetchUserContractList } from "src/app/services/manager.service";

const compare = (v1: any, v2: any) => {
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
};

@Component({
  selector: "app-contracts",
  templateUrl: "./contracts.component.html",
  styleUrls: ["./contracts.component.scss"],
})
export class ContractsComponent implements OnInit {
  public contracts: Contract[] = [];
  public page: number = 1;
  public pageCount: number = PAGE_COUNT;
  public totalPage: number = 0;
  public contractDocuments: ContractDocumentModel[];
  @ViewChildren(TableSortableHeader) headers: QueryList<TableSortableHeader>;
  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.getUserContractList();
  }

  setPage = (page) => {
    this.loadContracts(page);
  };

  handleOnSearch(event): void {}

  public onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    if (direction === "" || column === "") {
    } else {
      this.contracts = [...this.contracts].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === "asc" ? res : -res;
      });
    }
  }

  async loadContracts(page) {
    this.page = page;
    this.spinner.show();
    try {
      let { results, totalCount } = await getAll(this.page, this.pageCount);
      this.totalPage = totalCount;
      this.contracts = results;
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  }

  deleteContract = async (id) => {
    const modalRef = this.modalService.open(ConfirmationModalComponent);
    modalRef.componentInstance.message =
      "Are you sure you want to delete the contract?";
    modalRef.componentInstance.showCancel = true;
    modalRef.result.then((result: any) => {
      console.log("comp closed", result);
      if (result) {
        this.handleDelete(id);
      }
    });
  };

  handleDelete = async (id) => {
    this.spinner.show();
    try {
      let { success } = await deleteById(id);
      if (success) {
        this.toastr.success(SUCCESS_MESSAGES.CONTRACT_DELETED);
        this.loadContracts(1);
      }
    } catch (err) {
      this.toastr.error(
        err.message ? err.message : ERROR_MESSAGES.UNKNOWN_SERVER_ERR
      );
    } finally {
      this.spinner.hide();
    }
  };
  async getUserContractList() {
    try {
      this.spinner.show();
      const res = await fetchUserContractList();
      const sortData = res.result;
      sortData.sort((a, b) => (a.created_at > b.created_at ? -1 : 1));
      this.contractDocuments = sortData.map((el) => ({
        ...el,
        created_at: new Date(el.created_at).toISOString(),
      }));
      this.spinner.hide();
    } catch (error) {
      this.spinner.hide();
      console.error("Error: ", error);
    }
  }
}
