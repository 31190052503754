<app-page-title type="contract" hideSearch="true"></app-page-title>
<div>
  <div>
    <div class="table-responsive outer-table-manu">
      <div class="force-overflow"></div>
      <table class="table table-bordered table-fixed table-hub">
        <thead>
          <tr>
            <th scope="col" class="border-bottom-0">Date</th>
            <th scope="col" class="border-bottom-0 border-left-0"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngIf="!contractDocuments || contractDocuments?.length === 0"
            class="text-center"
          >
            <td colspan="4">No Contracts</td>
          </tr>
          <tr *ngFor="let contract of contractDocuments; let i = index">
            <td [ngClass]="i !== 0 ? 'border-top-0' : ''">
              {{ contract?.created_at | date : "yyyy-MM-dd" }}
            </td>
            <td
              [ngClass]="
                i !== 0 ? 'border-top-0 border-left-0' : 'border-left-0'
              "
            >
              <a
                [href]="contract.contract_url"
                target="_blank"
                class="btn btn-primary outline-none shadow-none border-0"
                >See contract</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
