<app-page-title type="contract" hideSearch="true"></app-page-title>
<div class="form-content">
  <h5 class="font-weight-bold pl-4 mb-4">Details</h5>
  <form [formGroup]="detailsForm" class="d-flex w-100 flex-column form-app">
    <div class="form-group" *ngFor="let formField of formFields">
      <dy-form-field
        [formGroup]="detailsForm"
        [formField]="formField"
        [search]="searchUser"
      ></dy-form-field>
    </div>
    <div class="row align-items-center mt-4">
      <div class="col-lg-2 col-form-label">Upload Contract File</div>
      <div class="col-lg-9">
        <div class="form-content__file">
          <label
            class="form-content__file-holder mb-0 d-flex align-items-center"
            *ngIf="!fileName"
          >
            <ng-container *ngIf="!loading">
              Choose contract file.
              <input [type]="'file'" (change)="onFileChange($event)" accept="application/pdf"/>
            </ng-container>
            <ng-container *ngIf="loading">
              Uploading...<cite class="ml-1"></cite>
            </ng-container>
          </label>
          <div
            class="form-content__file-holder d-flex align-items-center justify-content-between"
            *ngIf="fileName"
          >
            <span>file-name-dummy.png</span>

            <button>
              <img
                src="assets/images/close-icon.png"
                alt="X"
                (click)="removeFile()"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="form-group">
    <div class="mt-3 d-flex justify-content-end">
      <button
        type="button"
        class="font-weight-bold rounded border border-primary btn text-dark pl-5 pb-3 pr-5 pt-3"
        [routerLink]="['/manager/contracts']"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary rounded font-weight-bold text-white pl-5 pb-3 pr-5 pt-3"
        [disabled]="!detailsForm.valid"
        (click)="saveContract()"
      >
        Save
      </button>
    </div>
  </div>
</div>
